<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="名称" prop="MC" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        placeholder="请填写名称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="描述" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.MS"
                        :disabled="!isEdit"
                        placeholder="请填写名称"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            title: "",
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    MC: null,
                    MS: null,
                },
                itemCode: "500101",
                metaJson: [],
            },
            rules: {
                MC: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: ["blur"],
                    },
                ],
            },
        };
    },
    watch: {},
    mounted() {
        this.title = this.$route.meta.title;
    },
    methods: {},
};
</script>

<style></style>
